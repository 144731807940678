import React from 'react'
import './Footer.scss'
import { footerTexts } from './Footer.config'

const Footer = () => {
  return (
    <div id="kontakt" className="footer">
      <h4>Kontakt</h4>
      <div className="footer-wrapper">
        {footerTexts.map((footerText) => (
          <div key={footerText.id} className="footer-item">
            <h5>{footerText.title}</h5>
            {footerText.content}
          </div>
        ))}
        <div className='divider'></div>
        <div className='rights'>
          <span>Wszystkie prawa zastrzeżone &copy; <a href="https://linkedin.com/in/eryk-szczepanek" rel='noreferrer' target="_blank">OBWeb Service - Eryk Szczepanek</a></span>
          <span>NIP: 7692250916</span>
        </div>
      </div>
    </div>
  )
}

export default Footer
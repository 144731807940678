import React from 'react';
import './Workshops.scss';
import { workshopTexts } from './Workshops.config';

const Workshops = () => {
  return (
    <div className="workshops padding" id="warsztaty">
      <h2>Warsztaty</h2>
      <div className="workshops-wrapper">
        <div className='kids-section'>
          <h3>{workshopTexts.kids.title}</h3>
          <p>{workshopTexts.kids.description}</p>
        </div>
        <div className='adults-section'>
          <h3>{workshopTexts.adults.title}</h3>
          {workshopTexts.adults.description}
        </div>

      </div>
    </div>
  );
};

export default Workshops;
import React from 'react';
import Catering from './Catering/Catering';
import PrivateChef from './PrivateChef/PrivateChef';
import Workshops from './Workshops/Workshops';
import Gallery from './Gallery/Gallery';

const Body = () => {
  return (
    <div>
      <Catering/>
      <PrivateChef/>
      <Gallery/>
      <Workshops/>
    </div>
  )
}

export default Body
export const footerTexts = [
  {
    id: 1,
    title: "Telefon",
    content: <p>+48 697-738-127</p>,
  },
  {
    id: 2,
    title: "Email",
    content: <p>JakubChmielowski@gmail.com</p>,
  },
  {
    id: 3,
    title: "Dane firmy",
    content: (
      <>
        <p>HopBite Foods Jakub Chmielowski</p>
        <p>6941679062</p>
        <p>Wysoka 52-200, ul. Parkowa 19B</p>
      </>
    ),
  },
];

export const workshopTexts = {
  kids: {
    title: "Warsztaty dla dzieci",
    description: (
      <p>
        Jeśli Twoje dzieci uwielbiają spędzać czas w kuchni i są ciekawe nowych
        smaków, nasze warsztaty sushi to idealne miejsce, aby rozwijać ich
        kulinarne pasje! Podczas tych wyjątkowych zajęć najmłodsi będą mieli
        okazję zanurzyć się w fascynujący świat japońskiej kuchni, ucząc się,
        jak samodzielnie przygotować kolorowe i smaczne sushi. <br />
        <br />
        Warsztaty są prowadzone w przyjaznej, pełnej zabawy atmosferze, gdzie
        każde dziecko będzie mogło poczuć się jak prawdziwy szef kuchni. Pod
        okiem doświadczonych instruktorów, dzieci dowiedzą się, skąd pochodzi
        sushi, jakie składniki są potrzebne do jego przygotowania oraz jak je
        odpowiednio przygotować. Następnie, krok po kroku, nauczą się, jak
        skręcać i rolować swoje własne sushi, tworząc przy tym prawdziwe
        kulinarne arcydzieła. Ale to nie wszystko! Warsztaty sushi to nie tylko
        nauka gotowania – to przede wszystkim wspaniała zabawa, rozwijanie
        kreatywności i umiejętności manualnych. Dzieci będą miały okazję do
        wyrażenia siebie, tworząc sushi w różnych kształtach i z ulubionymi
        składnikami, co sprawi, że każdy talerz będzie unikalny.
        <br />
        <br /> Na zakończenie zajęć dzieci będą mogły skosztować swoich dzieł,
        co z pewnością dostarczy im wiele radości i satysfakcji z samodzielnie
        przygotowanego posiłku. To doskonała okazja, aby spędzić czas w
        kreatywny sposób, nauczyć się czegoś nowego i odkryć pasję, która może
        towarzyszyć im przez całe życie. Zapraszamy na warsztaty sushi, gdzie
        gotowanie staje się przygodą pełną smaku i zabawy!
      </p>
    ),
  },
  adults: {
    title: "Warsztaty dla dorosłych",
    description: (
      <p>
        Jeśli marzysz o odkryciu tajników sushi i zanurzeniu się w japońskiej
        sztuce kulinarnej, nasze warsztaty sushi dla dorosłych są idealną
        okazją, aby to zrobić. Pod okiem doświadczonego szefa kuchni nauczysz
        się, jak przygotować autentyczne sushi – od starannego doboru
        składników, przez przygotowanie idealnie kleistego ryżu, aż po techniki
        skręcania i rolowania, które pozwolą Ci stworzyć prawdziwe dzieła sztuki
        na talerzu.
        <br />
        <br /> To wydarzenie to nie tylko nauka, ale również świetna forma
        relaksu i zabawy. W atmosferze pełnej pasji do gotowania, poznasz
        sekrety jednego z najbardziej znanych i lubianych dań Japonii. Podczas
        warsztatów będziesz miał okazję do eksperymentowania z różnymi rodzajami
        sushi, tworząc zarówno tradycyjne, jak i bardziej nowoczesne kompozycje.
        Na koniec, wspólnie z innymi uczestnikami, zasiądziesz do degustacji, by
        cieszyć się smakiem własnoręcznie przygotowanych potraw.
        <br />
        <br /> To doskonała okazja, aby nie tylko rozwinąć swoje umiejętności
        kulinarne, ale także spędzić czas w inspirującej atmosferze, dzieląc się
        swoją nowo odkrytą pasją z innymi. Warsztaty sushi to niepowtarzalna
        szansa na zgłębienie kultury Japonii i doskonalenie swojego kulinarnego
        rzemiosła.
      </p>
    ),
  },
};

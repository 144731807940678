export const galleryImages = [
  {
    id: 1,
    src: "/assets/allphotos/p1.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 2,
    src: "/assets/allphotos/p2.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 3,
    src: "/assets/allphotos/p3.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 4,
    src: "/assets/allphotos/p4.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 5,
    src: "/assets/allphotos/p5.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 6,
    src: "/assets/allphotos/p6.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 7,
    src: "/assets/allphotos/p7.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 8,
    src: "/assets/allphotos/p8.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 9,
    src: "/assets/allphotos/p9.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 10,
    src: "/assets/allphotos/p10.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 11,
    src: "/assets/allphotos/p11.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 12,
    src: "/assets/allphotos/p12.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 13,
    src: "/assets/allphotos/p13.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 14,
    src: "/assets/allphotos/p14.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 15,
    src: "/assets/allphotos/p15.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 16,
    src: "/assets/allphotos/p16.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 17,
    src: "/assets/allphotos/p17.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 18,
    src: "/assets/allphotos/p18.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  },
  {
    id: 19,
    src: "/assets/allphotos/p19.jpeg",
    key: (image) => `galleryImage-${image.id}`,
    alt: (image) => `galleryImage-${image.id}`,
  }
]
export const bannerImages = [
  {
    id: 1,
    src: '/assets/banner/banner1.jpeg',
    alt: 'banner-1'
  },
  {
    id: 2,
    src: '/assets/banner/banner2.jpeg',
    alt: 'banner-2'
  },
]
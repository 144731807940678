export const cateringImages = [
  {
    id: 1,
    src: "/assets/catering/catering1.jpeg",
    key: (get) => `${get.id}-${get.src}`,
  },
  {
    id: 2,
    src: "/assets/catering/catering2.jpeg",
    key: (get) => `${get.id}-${get.src}`,
  },
  {
    id: 3,
    src: "/assets/catering/catering3.jpeg",
    key: (get) => `${get.id}-${get.src}`,
  }
];

export const cateringText = (
  <p>
    Klientom indywidualnym oferujemy kompleksową obsługę cateringową, która
    idealnie sprawdzi się podczas różnorodnych imprez okolicznościowych oraz
    spotkań towarzyskich. Niezależnie od tego, czy planujesz kameralne przyjęcie
    w gronie najbliższych, elegancką kolację, urodziny, rocznicę, chrzciny,
    komunie, czy nawet większe uroczystości rodzinne – zadbamy o każdy szczegół,
    aby Twoje wydarzenie było niezapomniane.
    <br />
    <br /> Nasze usługi cateringowe obejmują nie tylko przygotowanie pysznych i
    zróżnicowanych potraw, ale także profesjonalną obsługę, która dostosuje się
    do charakteru i stylu Twojej imprezy. Dbamy o to, aby każde danie było
    przygotowane z najwyższą starannością, wykorzystując świeże i najwyższej
    jakości składniki, które zadowolą nawet najbardziej wymagające podniebienia.
    <br />
    <br /> Współpracując z nami, możesz liczyć na pełne wsparcie przy
    organizacji Twojego wydarzenia. Pomożemy w doborze odpowiedniego menu, które
    będzie odpowiadać gustom Twoich gości, oraz zadbamy o estetyczną prezentację
    potraw, tworząc przy tym niepowtarzalną atmosferę. Bez względu na to, czy
    potrzebujesz eleganckiego cateringu na formalne przyjęcie, czy bardziej
    swobodnego bufetu na spotkanie w gronie przyjaciół – nasza oferta jest
    elastyczna i dostosowana do Twoich potrzeb.
    <br />
    <br /> Dzięki naszej obsłudze cateringowej możesz cieszyć się swoją imprezą
    bez stresu i trosk o przygotowanie posiłków czy obsługę gości. Jesteśmy tu,
    aby zadbać o wszystko, pozostawiając Tobie i Twoim bliskim czas na pełne
    radości i niezapomniane chwile.
  </p>
);

import React from 'react'
import MainPage from './pages/MainPage/MainPage'

const Root = () => {
  return (
    <div>
      <MainPage />
    </div>
  )
}

export default Root
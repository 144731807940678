import React, { useState } from "react";

const SingleImage = ({ image, isSmallGallery, chooseImageHandler }) => {
  const baseImageWidth = isSmallGallery ? 200 : 450;
  const baseImageHeight = isSmallGallery ? '200px' : '100%';
  const hoveredImageWidth = baseImageWidth + 30;
  const [isHovered, setIsHovered] = useState(false);

  const style = {
    height: baseImageHeight,
    width: isHovered ? `${hoveredImageWidth}px` : `${baseImageWidth}px`,
    objectFit: "cover",
    transition: "all .2s",
    cursor: 'pointer',
    opacity: isHovered ? 0.7 : 1,
  };

  return (
    <div className="single-image-gallery-module">
      <img
        src={image.src}
        alt={image.alt(image)}
        style={style}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => chooseImageHandler(image.id)}
      />
    </div>
  );
};

export default SingleImage;

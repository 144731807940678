export const privateChefText = (
  <p>
    <strong>
      Czy kiedykolwiek marzyłeś o zorganizowaniu idealnej imprezy bez stresu
      związanego z zakupami, gotowaniem i sprzątaniem?
    </strong>{" "}
    <br />
    <br />
    Wyobraź sobie przyjęcie, na którym nie musisz martwić się o żadne z tych
    obowiązków. Prywatny kucharz do wynajęcia jest tutaj, aby spełnić te
    marzenia i uczynić Twoje wydarzenie niezapomnianym. Dzięki mojej pomocy, Ty
    i Twoi goście możecie w pełni cieszyć się każdą chwilą, podczas gdy ja zajmę
    się resztą.
    <br />
    <br /> Kiedy zaprosisz mnie do swojego domu, przywiozę ze sobą wszystko, co
    potrzebne do przygotowania wykwintnych dań – od starannie dobranych
    składników po najlepsze narzędzia kuchenne. Zadbam o to, aby każde danie
    było nie tylko smaczne, ale także elegancko podane. Bez względu na to, czy
    organizujesz kameralne spotkanie z przyjaciółmi, rodzinne święto, czy
    większe przyjęcie – menu zostanie dostosowane do Twoich preferencji i
    potrzeb, aby zaspokoić gusta nawet najbardziej wymagających gości. <br />
    <br />
    Podczas gdy Ty będziesz bawił się i spędzał czas z bliskimi, ja dyskretnie
    zajmę się całą logistyką kulinarną. Każde danie zostanie przygotowane na
    miejscu, z dbałością o najdrobniejsze szczegóły. Zaserwuję je w odpowiednim
    momencie, dbając o to, by wszystko przebiegało zgodnie z planem i abyś Ty
    mógł w pełni skupić się na swoich gościach.
    <br />
    <br /> A po zakończeniu przyjęcia, nie będziesz musiał się martwić o
    sprzątanie. Zadbałem o to, aby kuchnia i miejsce, gdzie odbywa się
    przyjęcie, były pozostawione w idealnym porządku – tak, jakby nic się tam
    nie działo. Twoja jedyna troska? To, aby dobrze się bawić i cieszyć każdą
    chwilą spędzoną z bliskimi.
    <br />
    <br /> Zaproszenie prywatnego kucharza do swojego domu to gwarancja, że
    Twoje przyjęcie będzie wyjątkowe, a Ty będziesz mógł w pełni delektować się
    każdą chwilą, bez stresu i obowiązków. Pozwól mi zająć się wszystkim,
    podczas gdy Ty będziesz skupiał się na tym, co naprawdę ważne – na radości
    ze wspólnego świętowania.
  </p>
);

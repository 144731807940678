import React from 'react'
import Navigation from '../../sections/Navigation/Navigation'
import Footer from '../../sections/Footer/Footer'
import Banner from '../../sections/Banner/Banner'
import Body from '../../sections/Body/Body'

const MainPage = () => {
  return (
    <>
      <Navigation />
      <Banner />
      <Body />
      <Footer />
    </>
  )
}

export default MainPage
import React from 'react';
import { privateChefText } from './PrivateChef.config';

const PrivateChef = () => {
  return (
    <div className="privateChef padding" id="privatechef">
      <h2>Private Chef</h2>
      <div className="content-text">{privateChefText}</div>
    </div>
  )
}

export default PrivateChef
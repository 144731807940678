import React from 'react';
import './Banner.scss';
import { bannerImages } from './banner.config';

const Banner = () => {
  return (
    <div id="banner" className="banner">
      <div className="images-container">
      {bannerImages.map((image, index) => (
        <div key={`banner-${image.id}`} className={`banner-image ${index === 1 ? 'image-disabled' : ""}`}>
          <img src={image.src} alt={image.alt} />
        </div>
      ))}
      </div>
    </div>
  )
}

export default Banner
import React, { useState, useRef } from "react";
import SingleImage from "./SingleImage";
import "./GalleryModule.scss";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import Button from "../Button/Button";
import Modal from "react-bootstrap/Modal";

const GalleryModule = ({ images, isSmallGallery, onImageSelect }) => {
  const galleryDiv = useRef(null);

  const scrollLeft = () => {
    galleryDiv.current.scrollBy({ left: -450, behavior: "smooth" });
  };

  const scrollRight = () => {
    galleryDiv.current.scrollBy({ left: 450, behavior: "smooth" });
  };

  const chooseImageHandler = (imageId) => {
    if (onImageSelect) {
      onImageSelect(imageId);
    }
  };

  return (
    <div className="gallery-module">
      <div
        className="gallery-images"
        ref={galleryDiv}
        style={{ height: isSmallGallery ? "200px" : "450px" }}
      >
        {images &&
          images.map((image, index) => (
            <SingleImage
              key={`${image.id}-${index}`}
              image={image}
              index={index}
              isSmallGallery={isSmallGallery}
              chooseImageHandler={() => chooseImageHandler(image.id)}
            />
          ))}
      </div>
      <div className="gallery-buttons">
        <Button
          cb={scrollLeft}
          variant={"secondary"}
          icon={<FaChevronLeft />}
        ></Button>
        <Button
          cb={scrollRight}
          variant={"secondary"}
          icon={<FaChevronRight />}
        ></Button>
      </div>
    </div>
  );
};

const GalleryWithModal = ({ images }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [choosenPhoto, setChoosenPhoto] = useState(null);

  const openModal = (imageId) => {
    const choosenImage = images.find((image) => image.id === imageId);
    setChoosenPhoto(choosenImage);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const changeImageInModal = (imageId) => {
    const choosenImage = images.find((image) => image.id === imageId);
    setChoosenPhoto(choosenImage);
  };

  return (
    <div>
      <GalleryModule
        images={images}
        isSmallGallery={false}
        onImageSelect={openModal}
      />

      {isModalOpen && (
        <Modal
          show={isModalOpen}
          onHide={closeModal}
          size="lg"
          fullscreen={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Galeria</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-image">
              <div className="modal-image-wrapper">
                <img src={choosenPhoto.src} alt={choosenPhoto.alt} />
              </div>
              <GalleryModule
                images={images}
                isSmallGallery={true}
                onImageSelect={changeImageInModal}
                selectedImageId={choosenPhoto.id}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default GalleryWithModal;

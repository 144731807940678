import Root from "./Root"
import "./styles/settings.scss"

function App() {
  return (
    <div>
      <Root />
    </div>
  );
}

export default App;

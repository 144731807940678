import React from 'react';
import './Catering.scss';
import { cateringImages, cateringText } from './Catering.config';

const Catering = () => {
  return (
    <div className="catering padding" id="catering">
      <h2>Catering</h2>
      <div className="content-text">
        {cateringText}
      </div>
      <div className="content-image">
        {cateringImages.map((image) => (
          <div key={image.key(image)} className="catering-image">
            <img src={image.src} alt="catering" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Catering;
import React from 'react'
import './Button.scss'

const Button = ({content, variant, children, icon = null, cb, scale = 1, mobile}) => {
  const displayContent = content || children
  return (
    <button className={`${variant ? variant : 'primary'}`} style={{transform: `scale(${scale})`}} onClick={cb && cb}>
      {icon} <span style={{color: mobile ? 'black' : 'white'}}>{displayContent}</span>
    </button>
  )
}

export default Button
import React from "react";
import { galleryImages } from "./Gallery.config";
import GalleryModule from "../../../modules/GalleryModule/GalleryModule";

const Gallery = () => {
  return (
    <div className="gallery padding" id="galeria">
      <h2 style={{marginBottom: '40px'}}>Galeria</h2>
      <GalleryModule images={galleryImages} />
    </div>
  );
};

export default Gallery;

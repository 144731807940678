export const navItems = [
  {
    id: 1,
    name: 'Catering',
    href: '#catering'
  },
  {
    id: 2,
    name: 'Private Chef',
    href: '#privatechef'
  },
  {
    id: 3,
    name: 'Warsztaty',
    href: '#warsztaty'
  },
  {
    id: 4,
    name: 'Galeria',
    href: '#galeria'
  },
  {
    id: 5,
    name: 'Kontakt',
    href: '#kontakt'
  }
]
import React, { useState } from "react";
import { navItems } from "./Navigation.config";
import "./Navigation.scss";
import Modal from "react-bootstrap/Modal";
import Button from "../../modules/Button/Button";
import { GiHamburgerMenu } from "react-icons/gi";

const Navigation = () => {
  const [showModal, setShowModal] = useState(false);
  
  const handleModal = () => setShowModal(!showModal);

  const handleRedirect = (redirectTo) => {
    if (showModal) {
      setShowModal(false);
    }
    window.location.href = redirectTo;
  };

  return (
    <div id="navigation" className="navigation">
      <div className="brand-logo">
        <img src="/assets/banner/hopbite-logo.png" alt="brand-logo" />
      </div>
      <div className="navigation-items">
        <div className="navigation-mobile">
          <Button
            icon={<GiHamburgerMenu />}
            cb={handleModal}
          ></Button>
        </div>
        {navItems.map((item) => (
          <div
            className="navigation-item"
            key={`navItem-${item.id}`}
            id={`navItem-${item.href}`}
            onClick={() => handleRedirect(item.href)}
          >
            <Button>{item.name}</Button>
          </div>
        ))}
      </div>
      <Modal show={showModal} fullscreen={true} onHide={handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {navItems.map((item) => (
            <div
              key={`navItem-${item.id}`}
              id={`navItem-${item.href}`}
              onClick={() => handleRedirect(item.href)}
            >
              <Button mobile={true}>{item.name}</Button>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Navigation;